import { getStore } from '../store/store';
export default {
  store() {
    return getStore().getState();
  },
  getGACode() {
    return this.store().config.keys['google.ga.key'];
  },
  getPubnubConfig() {
    const store = this.store();
    const subscribeChannels = [
      store?.authentication?.authData?.channel,
      // store?.authentication?.authData?.chatChannel,
    ];
    return {
      keySet: {
        subscribeKey: store?.config?.keys['pubnub.subscription.key']
          ? store?.config?.keys['pubnub.subscription.key']
          : '',
      },
      channel: subscribeChannels,
    };
  },
  ENTITY_TYPE: {
    CUSTADMIN: 'ROLE_ADMIN_CUSTOMER_PARTNER',
    CUSTCSR: 'ROLE_CSR_CUSTOMER_PARTNER',
    CUSTREGIONALMGR: 'ROLE_REGIONAL_MANAGER_CUSTOMER_PARTNER',
    CUSTSUPERVISOR: 'ROLE_CSR_ROOT_CUSTOMER_PARTNER',
    FACILITYDISPATCHMGR: 'ROLE_FACILITY_DISPATCH_MANAGER',
    FACILITYDISPATCHEMP: 'ROLE_FACILITY_DISPATCH_EMPLOYEE',
    CUSTFACILITYMGR: 'ROLE_CUSTOMER_PARTNER_FACILITY_MANAGER',
    CUSTFACILITYADMIN: 'ROLE_CUSTOMER_PARTNER_FACILITY_ADMIN',
  },
  ROLE_TYPE: {
    ADMIN: 'Admin',
    CSR: 'CSR',
    REGIONAL_MANAGER: 'Regional Manager',
    SUPERVISOR: 'Supervisor',
    MANAGER: 'Manager',
    EMPLOYEE: 'Employee',
  },
  AUTH_ENTITY() {
    return this.store().authentication.authData.authEntity;
  },
  PREPEND_DEALER_IN_SERVICE_NAME() {
    const partnerCodes = ['caliber.collision'];
    const partner = process.env.REACT_APP_PARTNER_CODE || '';
    if (partnerCodes.indexOf(partner) >= 0) {
      return true;
    }
  },
  BUILD_VERSION: process.env.REACT_APP_BUILD_VERSION,
  API_BUILD_VERSION: process.env.REACT_APP_API_BUILD_VERSION,
  SENTRY_DSN:
    'https://2537328462be460c8358c046999cc817@o222561.ingest.sentry.io/5453033',
};
