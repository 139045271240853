import { put, takeLatest } from 'redux-saga/effects';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
} from '../config/configActionTypes';
import {
  GET_PENDING_QUEUE_DATA,
  GET_PENDING_QUEUE_SUCCESS,
  CLEAR_PENDING_QUEUE_DATA,
  GET_EXPIRED_QUEUE_DATA,
  GET_EXPIRED_QUEUE_SUCCESS,
  CLEAR_EXPIRED_QUEUE_DATA,
  GET_DISPATCH_QUEUE_DATA,
  GET_DISPATCH_QUEUE_SUCCESS,
  CLEAR_DISPATCH_QUEUE_DATA,
  GET_RESOLVED_QUEUE_DATA,
  GET_RESOLVED_QUEUE_SUCCESS,
  CLEAR_RESOLVED_QUEUE_DATA,
  TAKE_PENDING_CASE,
} from './mttDashboardActionTypes';
import { ERROR_OCCUR } from '../config/configActionTypes';
import {
  GET_PENDING_QUEUE,
  HTTP_GET,
  HTTP_POST,
  GET_EXPIRED_QUEUE,
  GET_DISPATCH_QUEUE,
  GET_RESOLVED_QUEUE,
  TAKE_CASE,
} from '../../constants/API';
import API from '../../utils/API';
import { postMessage } from '../../utils/eventHandler';
import { sendMessage, EventName } from '../../utils/eventHandler';

function* getPendingQueueData({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_PENDING_QUEUE(params.queueId, params.page, params.size),
      false,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      // response.data.content[0].pendingStatus =
      //   response.data.content[0].inQueue == false ? 'Unresolved' : 'New';
      response.data.meta = {
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
      yield put({ type: CLEAR_PENDING_QUEUE_DATA, response: null });
      yield put({ type: GET_PENDING_QUEUE_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.error,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* getExpiredQueueData({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_EXPIRED_QUEUE(params.from, params.to, params.page, params.size),
      false,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      response.data.meta = {
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
      yield put({ type: CLEAR_EXPIRED_QUEUE_DATA, response: null });
      yield put({ type: GET_EXPIRED_QUEUE_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.error,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* getDispatchQueueData({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_DISPATCH_QUEUE(params.from, params.to, params.page, params.size),
      false,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      response.data.meta = {
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
      yield put({ type: CLEAR_DISPATCH_QUEUE_DATA, response: null });
      yield put({ type: GET_DISPATCH_QUEUE_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.error,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* getResolvedQueueData({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_GET,
      GET_RESOLVED_QUEUE(params.from, params.to, params.page, params.size),
      false,
      true
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      response.data.meta = {
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
      };
      yield put({ type: CLEAR_RESOLVED_QUEUE_DATA, response: null });
      yield put({ type: GET_RESOLVED_QUEUE_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.error,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* takePendingCase({ params }: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(HTTP_POST, TAKE_CASE(params._id), false, true);
    yield put({ type: HIDE_LOADING });
    if (response) {
      postMessage.type = EventName.ANGULAR_SHOW_CASE_DETAIL;
      postMessage.data = params;
      sendMessage(postMessage);
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    postMessage.type = EventName.ANGULAR_SHOW_ERROR;
    postMessage.data = {
      error: error.response.data.error,
    };
    sendMessage(postMessage);
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}
function* mttDashboardWatcher(): any {
  yield takeLatest(GET_PENDING_QUEUE_DATA, getPendingQueueData);
  yield takeLatest(GET_EXPIRED_QUEUE_DATA, getExpiredQueueData);
  yield takeLatest(GET_RESOLVED_QUEUE_DATA, getResolvedQueueData);
  yield takeLatest(GET_DISPATCH_QUEUE_DATA, getDispatchQueueData);
  yield takeLatest(TAKE_PENDING_CASE, takePendingCase);
}

export default mttDashboardWatcher;
