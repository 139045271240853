import { ConfigInitialStateTypes } from '../../types/reducerTypes';
import {
  HIDE_LOADING,
  ERROR_OCCUR,
  SHOW_LOADING,
  ERROR_REMOVE,
  KEYS_SUCCESS,
  AUNGULAR_KEY_SUCCESS,
  REFRESH_CASE_DATA,
} from './configActionTypes';

const commonInitialState: ConfigInitialStateTypes = {
  isLoading: false,
  error: false,
  errorData: '',
  timer: 0,
  keys: {},
  refreshCaseData: false,
};
export const configReducer = (
  state = commonInitialState,
  action: any
): object => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ERROR_OCCUR:
      return {
        ...state,
        error: true,
        errorData: action.response.error,
      };
    case ERROR_REMOVE:
      return {
        ...state,
        error: false,
        errorData: '',
      };
    case KEYS_SUCCESS:
      return {
        ...state,
        keys: action.response.data[0].config,
      };
    case AUNGULAR_KEY_SUCCESS:
      return {
        ...state,
        timer: action.params.timer,
        keys: {
          ...state.keys,
          'pubnub.subscription.key': action.params.subKey,
        },
      };
    case REFRESH_CASE_DATA:
      return {
        ...state,
        refreshCaseData: action.params,
      };
    default:
      return state;
  }
};
