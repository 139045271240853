import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import './mttDashboard.scss';
import CustomeTable from './customTable';
import Pagination from '../pagination';
import translation from '../../utils/language';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

export interface Props {
  headers: any;
  pendingQueueContent: any;
  expiredQueueContent: any;
  dispatchQueueContent: any;
  resolvedQueueContent: any;
  getPendingQueueData: any;
  getExpiredQueueData: any;
  getDispatchQueueData: any;
  getResolvedQueueData: any;
  openCaseDetail: any;
  updateData: any;
}

export const MttDashboard: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [fromDate, updateFromDate] = useState(new Date());
  const [toDate, updateTodate] = useState(new Date());
  const initPage = 0;
  const maxPages = 3;

  const fromDateChange = (dt: any, queueType: string): void => {
    updateFromDate(dt);
    setPage(initPage);
    if (queueType === translation.EXPIRED) {
      props.getExpiredQueueData(
        initPage,
        moment(dt).format('MM/DD/yyyy'),
        moment(toDate).format('MM/DD/yyyy')
      );
    } else if (queueType === translation.RESOLVED) {
      props.getResolvedQueueData(
        initPage,
        moment(dt).format('MM/DD/yyyy'),
        moment(toDate).format('MM/DD/yyyy')
      );
    } else if (queueType === translation.DISPATCHED) {
      props.getDispatchQueueData(
        initPage,
        moment(dt).format('MM/DD/yyyy'),
        moment(toDate).format('MM/DD/yyyy')
      );
    }
  };
  const toDateChange = (dt: any, queueType: string): void => {
    updateTodate(dt);
    setPage(initPage);
    if (queueType === translation.EXPIRED) {
      props.getExpiredQueueData(
        initPage,
        moment(fromDate).format('MM/DD/yyyy'),
        moment(dt).format('MM/DD/yyyy')
      );
    } else if (queueType === translation.RESOLVED) {
      props.getResolvedQueueData(
        initPage,
        moment(fromDate).format('MM/DD/yyyy'),
        moment(dt).format('MM/DD/yyyy')
      );
    } else if (queueType === translation.DISPATCHED) {
      props.getDispatchQueueData(
        initPage,
        moment(fromDate).format('MM/DD/yyyy'),
        moment(dt).format('MM/DD/yyyy')
      );
    }
  };
  const validToDate = (currentDate: any): any => {
    const currentDateMoment = moment(currentDate).format('MM/DD/yyyy');
    const fromDateMoment = moment(fromDate).format('MM/DD/yyyy');
    const todaysDateMoment = moment(new Date()).format('MM/DD/yyyy');

    return (
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrBefore(
        moment(todaysDateMoment, 'MM/DD/yyyy')
      ) &&
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrAfter(
        moment(fromDateMoment, 'MM/DD/yyyy')
      )
    );
  };
  const validFromDate = (currentDate: any): any => {
    const currentDateMoment = moment(currentDate).format('MM/DD/yyyy');
    const toDateMoment = moment(toDate).format('MM/DD/yyyy');
    const rangDate = moment(
      new Date(new Date().setMonth(new Date().getMonth() - 3))
    ).format('MM/DD/yyyy');
    return (
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrBefore(
        moment(toDateMoment, 'MM/DD/yyyy')
      ) &&
      moment(currentDateMoment, 'MM/DD/yyyy').isSameOrAfter(
        moment(rangDate, 'MM/DD/yyyy')
      )
    );
  };
  const renderInputCalendar = (props: any, openCalendar: any): any => {
    return (
      <div>
        <input {...props} />
        <div className="date_icon" onClick={openCalendar}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </div>
      </div>
    );
  };
  const dateSelection = (queueType: string): any => {
    return (
      <Form className="custom_form">
        <Col sm={12} className="pl0 pr0 pt10">
          <Row>
            <Col sm={8}>
              <div className="width164">
                <Form.Group>
                  <Form.Label>Filter</Form.Label>
                  <div className="date_picker_area">
                    <Datetime
                      defaultValue="MM/DD/YY"
                      timeFormat={false}
                      className="custom_date_picker"
                      value={fromDate}
                      closeOnSelect={true}
                      onChange={(fromDate) =>
                        fromDateChange(fromDate, queueType)
                      }
                      isValidDate={validFromDate}
                      // @ts-ignore
                      renderInput={renderInputCalendar}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="to_separator">to</div>
              <div className="width164">
                <Form.Group>
                  <Form.Label></Form.Label>
                  <div className="date_picker_area">
                    <Datetime
                      defaultValue="MM/DD/YY"
                      timeFormat={false}
                      className="custom_date_picker"
                      value={toDate}
                      closeOnSelect={true}
                      onChange={(toDate) => toDateChange(toDate, queueType)}
                      isValidDate={validToDate}
                      // @ts-ignore
                      renderInput={renderInputCalendar}
                    />
                  </div>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Col>
      </Form>
    );
  };
  const getPendingQueuePageNumber = (pageNumber: number): void => {
    setPage(pageNumber);
    props.getPendingQueueData(pageNumber);
  };
  const getExpiredQueuePageNumber = (pageNumber: number): void => {
    setPage(pageNumber);
    updateFromDate(fromDate);
    updateTodate(toDate);
    props.getExpiredQueueData(
      pageNumber,
      moment(fromDate).format('MM/DD/yyyy'),
      moment(toDate).format('MM/DD/yyyy')
    );
  };
  const getResolvedQueuePageNumber = (pageNumber: number): void => {
    setPage(pageNumber);
    updateFromDate(fromDate);
    updateTodate(toDate);
    props.getResolvedQueueData(
      pageNumber,
      moment(fromDate).format('MM/DD/yyyy'),
      moment(toDate).format('MM/DD/yyyy')
    );
  };
  const getDispatchQueuePageNumber = (pageNumber: number): void => {
    setPage(pageNumber);
    updateFromDate(fromDate);
    updateTodate(toDate);
    props.getDispatchQueueData(
      pageNumber,
      moment(fromDate).format('MM/DD/yyyy'),
      moment(toDate).format('MM/DD/yyyy')
    );
  };
  const openCaseDetail = (rowData: any): void => {
    props.openCaseDetail(rowData);
  };
  const handleQueueClick = (queueKey: string): void => {
    switch (queueKey) {
      case translation.PENDING:
        setPage(initPage);
        props.getPendingQueueData(initPage);
        break;
      case translation.EXPIRED:
        setPage(initPage);
        updateFromDate(new Date());
        updateTodate(new Date());
        props.getExpiredQueueData(
          initPage,
          moment(new Date()).format('MM/DD/yyyy'),
          moment(new Date()).format('MM/DD/yyyy')
        );
        break;
      case translation.RESOLVED:
        setPage(initPage);
        updateFromDate(new Date());
        updateTodate(new Date());
        props.getResolvedQueueData(
          initPage,
          moment(new Date()).format('MM/DD/yyyy'),
          moment(new Date()).format('MM/DD/yyyy')
        );
        break;
      case translation.DISPATCHED:
        setPage(initPage);
        updateFromDate(new Date());
        updateTodate(new Date());
        props.getDispatchQueueData(
          initPage,
          moment(new Date()).format('MM/DD/yyyy'),
          moment(new Date()).format('MM/DD/yyyy')
        );
        break;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="content_area admin_view pr0 pt0 pb0 pl60">
          <Col md={12} className="pl15 pr15">
            <div className="title_wrap">
              <div className="main_title mb10">Queue Status</div>
            </div>
          </Col>

          <Col md={12} className="pl0 pr0">
            <Tabs
              className="custom_tabs pl10"
              defaultActiveKey="pending"
              id="uncontrolled-tab-example"
              onSelect={(queueKey: string) => handleQueueClick(queueKey)}
            >
              <Tab
                eventKey={translation.PENDING}
                title={
                  props.pendingQueueContent?.meta?.totalElements > 0
                    ? `Pending (${props.pendingQueueContent.meta.totalElements})`
                    : `Pending`
                }
              >
                <Col sm={12} className="tables_view">
                  <CustomeTable
                    headers={props.headers.pendingQueue}
                    data={props.pendingQueueContent?.content}
                    renderIcon={true}
                    onRowClick={(rowData: any) => openCaseDetail(rowData)}
                    type={translation.PENDING}
                    updateData={props.updateData}
                  />
                  <Pagination
                    totalPages={props.pendingQueueContent?.meta?.totalPages}
                    getPageNumber={getPendingQueuePageNumber}
                    maxPages={maxPages}
                    reSetPage={page}
                  ></Pagination>
                </Col>
              </Tab>

              <Tab
                eventKey={translation.EXPIRED}
                title={
                  props.expiredQueueContent?.meta?.totalElements > 0
                    ? `Expired (${props.expiredQueueContent.meta.totalElements})`
                    : `Expired`
                }
              >
                <Col sm={12} className="tables_view">
                  {dateSelection(translation.EXPIRED)}
                  <CustomeTable
                    headers={props.headers.expiredHeader}
                    data={props.expiredQueueContent?.content}
                    renderIcon={false}
                    type={translation.EXPIRED}
                  />
                  <Pagination
                    totalPages={props.expiredQueueContent?.meta?.totalPages}
                    getPageNumber={getExpiredQueuePageNumber}
                    maxPages={maxPages}
                    reSetPage={page}
                  ></Pagination>
                </Col>
              </Tab>
              <Tab
                eventKey={translation.RESOLVED}
                title={
                  props.resolvedQueueContent?.meta?.totalElements > 0
                    ? `Resolved (${props.resolvedQueueContent.meta.totalElements})`
                    : `Resolved`
                }
              >
                <Col sm={12} className="tables_view">
                  {dateSelection(translation.RESOLVED)}
                  <CustomeTable
                    headers={props.headers.resolvedHeader}
                    data={props.resolvedQueueContent?.content}
                    renderIcon={false}
                    type={translation.RESOLVED}
                  />
                  <Pagination
                    totalPages={props.resolvedQueueContent?.meta?.totalPages}
                    getPageNumber={getResolvedQueuePageNumber}
                    maxPages={maxPages}
                    reSetPage={page}
                  ></Pagination>
                </Col>
              </Tab>

              <Tab
                eventKey={translation.DISPATCHED}
                title={
                  props.dispatchQueueContent?.meta?.totalElements > 0
                    ? `Dispatched (${props.dispatchQueueContent.meta.totalElements})`
                    : `Dispatched`
                }
              >
                <Col sm={12} className="tables_view">
                  {dateSelection(translation.DISPATCHED)}
                  <CustomeTable
                    headers={props.headers.dispatchHeader}
                    data={props.dispatchQueueContent?.content}
                    renderIcon={false}
                    type={translation.DISPATCHED}
                  />
                  <Pagination
                    totalPages={props.dispatchQueueContent?.meta?.totalPages}
                    getPageNumber={getDispatchQueuePageNumber}
                    maxPages={maxPages}
                    reSetPage={page}
                  ></Pagination>
                </Col>
              </Tab>
            </Tabs>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default MttDashboard;
