const PERSIST_STORE_KEY = 'parner-portal';
export const loadState = (): any => {
  try {
    const serializedState = localStorage.getItem(PERSIST_STORE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(PERSIST_STORE_KEY, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const clearState = (): void => {
  try {
    localStorage.removeItem(PERSIST_STORE_KEY);
  } catch (err) {
    // Ignore write errors.
  }
};
