import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import dayjs from 'dayjs';
import { useInterval } from 'react-use';
import { getStore } from '../store/store';

interface Props {
  data: any;
  handleDisabledRow: any;
}

export const Timer: React.FC<Props> = (props) => {
  const store = getStore().getState();
  const interval = 10000;
  const jobExpireMinute = store.config.timer ? store.config.timer : 15;
  const currentTime = new Date().getTime();
  const jobTime = props.data.createdAt;

  const [valuesIndex, setValuesIndex] = useState(
    dayjs(currentTime).diff(dayjs(jobTime), 'minute')
  );

  useInterval(
    () => {
      setValuesIndex(
        dayjs(new Date().getTime()).diff(dayjs(jobTime), 'minute')
      );
    },
    Number(valuesIndex) < jobExpireMinute ? interval : null
  );

  useEffect(() => {
    if (Number(valuesIndex) >= jobExpireMinute)
      props.handleDisabledRow(props.data);
    if (!props.data.inQueue) setValuesIndex(jobExpireMinute);
  }, [Number(valuesIndex)]);

  if (Number(valuesIndex) < jobExpireMinute) {
    return (
      <CircularProgressbar
        value={Number(((valuesIndex / jobExpireMinute) * 100).toFixed(2))}
        strokeWidth={50}
        styles={{
          path: {
            stroke: `rgba(243, 243, 243)`,
            strokeLinecap: 'butt',
          },
          trail: {
            stroke: ' #b2c6cd',
          },
        }}
      />
    );
  } else {
    return (
      <CircularProgressbar
        value={100}
        strokeWidth={50}
        styles={{
          path: {
            stroke: `rgba(243, 243, 243, 50)`,
            strokeLinecap: 'butt',
          },
          trail: {
            stroke: ' #b2c6cd',
          },
        }}
      />
    );
  }
};

export default Timer;
