import config from './config';
import PubNub from 'pubnub';
import { printLog } from './logging';
import { getStore } from '../store/store';
import { REFRESH_CASE_DATA } from '../saga/config/configActionTypes';

let pubNubClient: any;
let refreshCaseData: boolean;
const pubnubMessageKeys = {
  jobKeys: [
    '3002',
    '3003',
    '3004',
    '3005',
    '3006',
    '3007',
    '3009',
    '3011',
    '3012',
    '3013',
    '3012',
    '3018',
    '3021',
    '3022',
    '3024',
    '3025',
    '3027',
    '3032',
    '3041',
    '3067',
    '4002',
  ],
};

const proceedPubnubData = async (pubnubData: any): Promise<void> => {
  printLog('pubnub procees', pubnubData.message);
  printLog('pubnub message Key:::', pubnubData.message.messageKey);
  const store = getStore();
  const actionData = { type: '', params: {} };
  if (pubnubData?.message?.messageKey) {
    const data = pubnubData.message;
    if (data.messageKey === '4004') {
      actionData.type = REFRESH_CASE_DATA;
      actionData.params = true;
      store.dispatch(actionData);
    }
  }
};

export const pubnubConnect = (): void => {
  const pubnubConfig = config.getPubnubConfig();
  if (pubnubConfig?.keySet?.subscribeKey !== '') {
    // printLog('pubnubConfig', pubnubConfig);
    pubNubClient = new PubNub(pubnubConfig?.keySet);
    // printLog('pubNubClient', pubNubClient);
    pubNubClient.addListener({
      status: function (statusEvent: any) {
        printLog('pubnub statusEvent', statusEvent);
        if (statusEvent.category === 'PNConnectedCategory') {
          // var payload = {
          //   my: 'payload'
          // };
          // pubnub.publish(
          //   {
          //     message: payload
          //   },
          //   function(status) {
          //     // handle publish response
          //   }
          // );
        }
      },
      message: function (message: any) {
        printLog('pubnub received', message);
        proceedPubnubData(message);
        //   this.props.storePubnub(message);
        // handle message
      },
      presence: function (presenceEvent: any) {
        // handle presence
        printLog('pubnub presence', presenceEvent);
      },
    });
    pubNubClient.subscribe({
      channels: pubnubConfig.channel,
    });
  }
};
export const pubnubDisconnect = (): void => {
  if (pubNubClient) {
    printLog('pubnubDisconnect');
    const pubnubConfig = config.getPubnubConfig();
    pubNubClient.unsubscribe({
      channels: pubnubConfig.channel,
    });
  }
  // if (pubnubConfig) {
  //   const pubNubClient = new PubNub(pubnubConfig?.keySet);
  // }
};
