import { put, takeLatest, call } from 'redux-saga/effects';
import history from '../../utils/history';
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ERROR_REMOVE,
  KEYS_REQUEST,
  KEYS_SUCCESS,
  KEYS_FAILURE,
  AUTH_EXPIRE,
} from '../config/configActionTypes';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOGOUT,
} from './loginActionTypes';
import { ERROR_OCCUR } from '../config/configActionTypes';
import {
  LOGIN_API,
  HTTP_POST,
  HTTP_GET,
  RESET_PASSWORD_API,
  GET_KEYS_API,
  LOGOUT_API,
} from '../../constants/API';
import API from '../../utils/API';
import { routes } from '../../constants/utilConstants';
import translation from '../../utils/language';
import config from '../../utils/config';

function forwardTo(location: string): void {
  history.push(location);
}
function getUserRole(authority: string): any {
  let authEntity;
  let authRole;
  switch (authority) {
    case config.ENTITY_TYPE.CUSTCSR:
      authRole = config.ROLE_TYPE.CSR;
      break;

    case config.ENTITY_TYPE.CUSTADMIN:
    case config.ENTITY_TYPE.CUSTFACILITYADMIN:
      authRole = config.ROLE_TYPE.ADMIN;
      break;

    case config.ENTITY_TYPE.CUSTREGIONALMGR:
      authRole = config.ROLE_TYPE.REGIONAL_MANAGER;
      break;

    case config.ENTITY_TYPE.CUSTSUPERVISOR:
      authRole = config.ROLE_TYPE.SUPERVISOR;
      break;

    case config.ENTITY_TYPE.CUSTFACILITYMGR:
    case config.ENTITY_TYPE.FACILITYDISPATCHMGR:
      authRole = config.ROLE_TYPE.MANAGER;
      break;
    case config.ENTITY_TYPE.FACILITYDISPATCHEMP:
      authRole = config.ROLE_TYPE.EMPLOYEE;
      break;
  }
  switch (authority) {
    case config.ENTITY_TYPE.CUSTADMIN:
    case config.ENTITY_TYPE.CUSTCSR:
    case config.ENTITY_TYPE.CUSTREGIONALMGR:
    case config.ENTITY_TYPE.CUSTSUPERVISOR:
      authEntity = translation.ENTITY.PARTNER;
      break;

    case config.ENTITY_TYPE.CUSTFACILITYADMIN:
    case config.ENTITY_TYPE.CUSTFACILITYMGR:
    case config.ENTITY_TYPE.FACILITYDISPATCHEMP:
    case config.ENTITY_TYPE.FACILITYDISPATCHMGR:
      authEntity = translation.ENTITY.FACILITY;
      break;
  }
  return { authEntity, authRole };
}
function* verifyLogin(payload: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(HTTP_POST, LOGIN_API, payload.param);
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      const { authEntity, authRole } = getUserRole(
        response.data.data[0].authority
      );
      response.data.data[0].authEntity = authEntity;
      response.data.data[0].authRole = authRole;
      yield put({ type: LOGIN_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}
function* forgotPassword(payload: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(
      HTTP_POST,
      RESET_PASSWORD_API(payload.param.email)
    );
    yield put({ type: HIDE_LOADING });
    if (response?.data) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}
function* getKeys(): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(HTTP_GET, GET_KEYS_API, null, true);
    // yield put({ type: HIDE_LOADING }); loader hides before caseList API response due to this.
    if (response?.data) {
      yield put({ type: KEYS_SUCCESS, response: response.data });
      yield put({ type: ERROR_REMOVE });
      //pubnubConnect();
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    yield put({ type: KEYS_FAILURE });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}
function* authExpire(error: any): any {
  yield put({ type: ERROR_OCCUR, response: error.response.data });
  yield call(forwardTo, routes.LOGIN);
}
function* logoutUser(payload: any): any {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield API(HTTP_GET, LOGOUT_API(payload.uid), null, true);
    yield put({ type: HIDE_LOADING });
    if (response?.status === 200) {
      // pubnubDisconnect();
      yield call(forwardTo, routes.LOGIN);
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    if (error?.response?.data) {
      yield put({ type: ERROR_OCCUR, response: error.response.data });
    } else {
      yield put({ type: ERROR_OCCUR, response: { error: error.message } });
    }
  }
}

function* loginWatcher(): any {
  yield takeLatest(LOGIN_REQUEST, verifyLogin);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(KEYS_REQUEST, getKeys);
  yield takeLatest(AUTH_EXPIRE, authExpire);
  yield takeLatest(LOGOUT, logoutUser);
}

export default loginWatcher;
