import React from 'react';
import { Table } from 'react-bootstrap';
import './mttDashboard.scss';
import get from 'lodash/get';
import Timer from '../timer';
import dayjs from 'dayjs';

export interface Props {
  headers: any[];
  data: any[];
  renderIcon?: any;
  onRowClick?: any;
  type?: string;
  updateData?: any;
}

export const CustomTable: React.FC<Props> = (props) => {
  const handleRowClick = (rowData: any): any => {
    props.onRowClick(rowData);
  };

  const setDisabledRow = (rowData: any, indexRow: number): any => {
    if (rowData.inQueue && !rowData.isDisabled) {
      rowData.isDisabled = true;
      const params = {
        indexRow: indexRow,
        response: rowData,
      };
      props.updateData(params);
    }
  };

  const renderHeaders = (): any => {
    return (
      <tr>
        {props.renderIcon && <th></th>}
        {props.headers.map((header: any, index: any) => {
          return <th key={index}>{header.value}</th>;
        })}
      </tr>
    );
  };

  const renderBody = (): any => {
    return props.data?.map((rowData: any, indexRow: number) => {
      return (
        <tr
          key={indexRow}
          onClick={props.onRowClick && (() => handleRowClick(rowData))}
          className={rowData.isDisabled ? 'disabled' : ''}
        >
          {props.renderIcon && (
            <td>
              <Timer
                data={rowData}
                handleDisabledRow={(data: any) =>
                  setDisabledRow(data, indexRow)
                }
              />
            </td>
          )}
          {props.headers.map((header: any, indexValue: number) => {
            if (header.key === 'eventTimeStamp') {
              const dispatched = dayjs(rowData.eventTimeStamp).format(
                'MM/DD/YY h:mm A'
              );
              return <td key={indexValue}>{dispatched}</td>;
            } else if (header.key === 'inQueue') {
              const assignTime = dayjs(rowData.assignmentTime).format('h:mm A');
              const csr = rowData.callCenterCsr;
              const status = rowData.inQueue ? 'New' : 'Unresolved';
              return (
                <td key={indexValue}>
                  <div>{status}</div>
                  {status === 'Unresolved' && (
                    <div className="taken_time">
                      Taken: {assignTime} - {csr}
                    </div>
                  )}
                </td>
              );
            } else if (header.key === '_id') {
              return (
                <td className="case_id" key={indexValue}>
                  #{rowData._id}
                </td>
              );
            } else {
              return <td key={indexValue}>{get(rowData, header.key)}</td>;
            }
          })}
        </tr>
      );
    });
  };

  return (
    <Table
      hover
      className={`round_border ${
        props.type === 'pending' ? 'pending_queue pt20' : ''
      }`}
    >
      <thead>{renderHeaders()}</thead>
      <tbody>
        {props.data?.length > 0 ? (
          renderBody()
        ) : (
          <tr>
            <td colSpan={7} className="no_data">
              <div className="no_data_found">No Data Found</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CustomTable;
