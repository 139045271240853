export const routes = {
  LOGIN: `/login`,
  RESET_PASSWORD: `/reset-password`,
  HOME: `/home`,
  SIDEBAR: `/sidebar`,
  BUSINESS_INFO: `/business-info`,
  REPORT: `/report`,
  MTT_DASHBOARD: `/mtt-dashboard`,
};
export const caseStatus: any = {
  'All Cases': `ALL CASES`,
  Open: `LIVE`,
  Closed: `COMPLETE`,
  caseCallSize: 100,
  searchCaseCallSize: 20,
};

export const serviceTypes = {
  RSA_SCHEDULED_SERVICE: 'RSA_SCHEDULED_SERVICE',
  RSA_SCHEDULED_DROPOFF: 'RSA_SCHEDULED_DROPOFF',
};

export const holdReason = {
  SPECIALIST_REVIEW_REQUIRED: 'SPECIALIST_REVIEW_REQUIRED',
};

export const validationExpression = {
  EMAIL: /\S+@\S+\.\S+/,
};

export const EVENTS = {
  CASE_DETAIL_OPEN: {
    NAME: 'CASE_DETAIL_OPEN',
    EVENT_ID: '20000',
  },
  CASE_DETAIL_CLOSE: {
    NAME: 'CASE_DETAIL_CLOSE',
    EVENT_ID: '20001',
  },
  DAMAGE_DETAIL: {
    EVENT_ID: '57003',
  },
};

export const facility = {
  tag: 'KEY_DROP_OFF',
};

export const formatPhoneNumber = (phoneNumberString: number): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+1(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return 'N/A';
};

export const titleCase = (input: string): string => {
  const splitStr = input.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  const finalValue = splitStr.join(' ');
  return finalValue;
};

export const pendingHeader = [
  {
    key: '_id',
    value: 'Case Id',
  },
  {
    key: 'creatorName',
    value: 'Creator',
  },
  {
    key: 'inQueue',
    value: 'Status',
  },
  {
    key: 'customerName',
    value: 'Customer',
  },
  {
    key: `jobs[0].service.name`,
    value: 'JobType',
  },
  {
    key: 'jobs[0].internalInfo.disablementReason',
    value: 'Disablement Reason',
  },
];

export const expiredHeader = [
  {
    key: '_id',
    value: 'Case Id',
  },
  {
    key: 'contactName',
    value: 'Customer',
  },
  {
    key: 'jobs[0].service.name',
    value: 'Job Type',
  },
  {
    key: 'jobs[0].internalInfo.disablementReason',
    value: 'Disablement Reason',
  },
  {
    key: 'eventTimeStamp',
    value: 'Expired',
  },
];

export const resolvedHeader = [
  {
    key: '_id',
    value: 'Case Id',
  },
  {
    key: 'contactName',
    value: 'Customer',
  },
  {
    key: 'jobs[0].service.name',
    value: 'Job Type',
  },
  {
    key: 'jobs[0].internalInfo.disablementReason',
    value: 'Disablement Reason',
  },
  {
    key: `eventOperatedByUser`,
    value: 'Agent',
  },
  {
    key: 'eventTimeStamp',
    value: 'Resolved',
  },
];

export const dispatchHeader = [
  {
    key: '_id',
    value: 'Case Id',
  },
  {
    key: 'contactName',
    value: 'Customer',
  },
  {
    key: 'jobs[0].service.name',
    value: 'Job Type',
  },
  {
    key: 'jobs[0].internalInfo.disablementReason',
    value: 'Disablement Reason',
  },
  {
    key: `eventOperatedByUser`,
    value: 'Agent',
  },
  {
    key: 'eventTimeStamp',
    value: 'Dispatched',
  },
];
