import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MTTDashboardScreen from '../components/mttDashboard/mttDashboard';
import {
  AuthInitialStateTypes,
  ConfigInitialStateTypes,
  MTTDashboardData,
  QueueData,
} from '../types/reducerTypes';
import { Dispatch } from 'redux';
import { ANGULAR_AUTH_SUCCESS } from '../saga/login/loginActionTypes';
import {
  AUNGULAR_KEY_SUCCESS,
  REFRESH_CASE_DATA,
} from '../saga/config/configActionTypes';
import {
  GET_PENDING_QUEUE_DATA,
  GET_EXPIRED_QUEUE_DATA,
  GET_DISPATCH_QUEUE_DATA,
  GET_RESOLVED_QUEUE_DATA,
  PENDING_QUEUE_UPDATE_DATA,
  TAKE_PENDING_CASE,
} from '../saga/mttDashboard/mttDashboardActionTypes';
import { postMessage } from '../utils/eventHandler';
import { sendMessage, Events, EventName } from '../utils/eventHandler';
import { printLog } from '../utils/logging';
import {
  pendingHeader,
  expiredHeader,
  dispatchHeader,
  resolvedHeader,
} from '../constants/utilConstants';
import translation from '../utils/language';
import moment from 'moment';
import { pubnubConnect } from '../utils/pubnubUtility';

interface Props {
  authentication: AuthInitialStateTypes;
  config: ConfigInitialStateTypes;
  mttDashboard: MTTDashboardData;
  pendingQueueData: QueueData;
  expiredQueueData: QueueData;
  dispatchQueueData: QueueData;
  resolvedQueueData: QueueData;
  refreshCaseData: boolean;
  setAuthToken: (Object: any) => void;
  setPubnubSubKey: (Object: any) => void;
  getPendingData: (params: Payload) => void;
  getExpiredData: (params: Options) => void;
  getDispatchData: (params: Options) => void;
  getResolvedData: (params: Options) => void;
  updatePendingQueue: (params: any) => void;
  takePendingCase: (Object: any) => void;
  setRefreshCaseData: (params: boolean) => void;
}

interface AuthPayload {
  queueUd: number;
  authToken: string;
  channel: string;
}
interface Payload {
  queueId: number;
  page: number;
  size: number;
}
interface ConfigPayload {
  subKey: string;
  timer: number;
}
interface Options {
  from: any;
  to: any;
  page: number;
  size: number;
}

const headersData = {
  pendingQueue: pendingHeader,
  expiredHeader: expiredHeader,
  dispatchHeader: dispatchHeader,
  resolvedHeader: resolvedHeader,
};
class MTTDashboard extends React.Component<Props> {
  queueId: any;
  initPage: number;
  size: number;
  fromDate: any;
  toDate: any;
  constructor(props: Props) {
    super(props);
    this.initPage = translation.MTT_INIT_PAGE;
    this.size = translation.MTT_QUEUE_SIZE;
    this.queueId = null;
    this.fromDate = moment(new Date()).format('MM/DD/yyyy');
    this.toDate = moment(new Date()).format('MM/DD/yyyy');
    this.receiveMessage = this.receiveMessage.bind(this);
    this.getPendingQueueData = this.getPendingQueueData.bind(this);
    this.getExpiredQueueData = this.getExpiredQueueData.bind(this);
    this.getDispatchQueueData = this.getDispatchQueueData.bind(this);
    this.getResolvedQueueData = this.getResolvedQueueData.bind(this);
    this.openCaseDetail = this.openCaseDetail.bind(this);
    this.handleUpdateData = this.handleUpdateData.bind(this);
  }

  setRefreshCaseData = function (this: MTTDashboard): void {
    this.props.setRefreshCaseData(false);
    this.getPendingQueueData(this.initPage);
  };

  startTimer = function (this: MTTDashboard): void {
    setInterval(() => {
      if (this.props.refreshCaseData) {
        this.setRefreshCaseData();
      }
    }, 60000);
  };

  componentDidMount(): void {
    /* TODO: Receiving blank event on did mount that need to check */
    Events.remove(EventName.ANGULAR_INIT_DATA);
    Events.on(EventName.ANGULAR_INIT_DATA, this.receiveMessage);
    sendMessage(postMessage);
    this.startTimer();
  }

  handleUpdateData = function (this: MTTDashboard, params: any): void {
    this.props.updatePendingQueue(params);
  };

  openCaseDetail = function (this: MTTDashboard, caseDetail: any): void {
    if (caseDetail.inQueue) {
      this.props.takePendingCase(caseDetail);
    } else {
      postMessage.type = EventName.ANGULAR_SHOW_CASE_DETAIL;
      postMessage.data = caseDetail;
      sendMessage(postMessage);
    }
  };

  receiveMessage = function (this: MTTDashboard, receiveData: any): void {
    try {
      printLog('receive message==>', receiveData);
      this.queueId = receiveData.queueId;
      const data = {
        queueId: receiveData.queueId,
        authToken: receiveData.authToken,
        channel: receiveData.channel,
      };
      this.props.setAuthToken(data);
      const params = {
        subKey: receiveData.sub_key,
        timer: receiveData.timer,
      };
      this.props.setPubnubSubKey(params);
      pubnubConnect();
      this.getPendingQueueData(this.initPage);
      this.getExpiredQueueData(this.initPage, this.fromDate, this.toDate);
      this.getDispatchQueueData(this.initPage, this.fromDate, this.toDate);
      this.getResolvedQueueData(this.initPage, this.fromDate, this.toDate);
    } catch (error) {
      printLog(error);
    }
  };

  getPendingQueueData = function (this: MTTDashboard, page: number): void {
    const params = {
      queueId: this.queueId,
      page: page,
      size: this.size,
    };
    if (this.queueId) this.props.getPendingData(params);
  };

  getExpiredQueueData = function (
    this: MTTDashboard,
    page: number,
    fromDate: any,
    toDate: any
  ): void {
    const params = {
      from: fromDate,
      to: toDate,
      page: page,
      size: this.size,
    };
    if (this.queueId) this.props.getExpiredData(params);
  };

  getDispatchQueueData = function (
    this: MTTDashboard,
    page: number,
    fromDate: any,
    toDate: any
  ): void {
    const params = {
      from: fromDate,
      to: toDate,
      page: page,
      size: this.size,
    };
    if (this.queueId) this.props.getDispatchData(params);
  };

  getResolvedQueueData = function (
    this: MTTDashboard,
    page: number,
    fromDate: any,
    toDate: any
  ): void {
    const params = {
      from: fromDate,
      to: toDate,
      page: page,
      size: this.size,
    };
    if (this.queueId) this.props.getResolvedData(params);
  };

  render(): React.ReactNode {
    return (
      <>
        <MTTDashboardScreen
          pendingQueueContent={this.props.pendingQueueData}
          expiredQueueContent={this.props.expiredQueueData}
          dispatchQueueContent={this.props.dispatchQueueData}
          resolvedQueueContent={this.props.resolvedQueueData}
          headers={headersData}
          getPendingQueueData={this.getPendingQueueData}
          getExpiredQueueData={this.getExpiredQueueData}
          getDispatchQueueData={this.getDispatchQueueData}
          getResolvedQueueData={this.getResolvedQueueData}
          openCaseDetail={this.openCaseDetail}
          updateData={this.handleUpdateData}
        />
      </>
    );
  }
}
const mapStateToProps = (props: Props): object => {
  return {
    pendingQueueData: props.mttDashboard.pendingQueue,
    expiredQueueData: props.mttDashboard.expiredQueue,
    dispatchQueueData: props.mttDashboard.dispatchQueue,
    resolvedQueueData: props.mttDashboard.resolvedQueue,
    refreshCaseData: props.config.refreshCaseData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
  return {
    setAuthToken: (params: AuthPayload) => {
      dispatch({ type: ANGULAR_AUTH_SUCCESS, params });
    },
    setPubnubSubKey: (params: ConfigPayload) => {
      dispatch({ type: AUNGULAR_KEY_SUCCESS, params });
    },
    getPendingData: (params: Payload) => {
      dispatch({ type: GET_PENDING_QUEUE_DATA, params });
    },
    getExpiredData: (params: Options) => {
      dispatch({ type: GET_EXPIRED_QUEUE_DATA, params });
    },
    getDispatchData: (params: Options) => {
      dispatch({ type: GET_DISPATCH_QUEUE_DATA, params });
    },
    getResolvedData: (params: Options) => {
      dispatch({ type: GET_RESOLVED_QUEUE_DATA, params });
    },
    updatePendingQueue: (params: any) => {
      dispatch({ type: PENDING_QUEUE_UPDATE_DATA, params });
    },
    takePendingCase: (params: any) => {
      dispatch({ type: TAKE_PENDING_CASE, params });
    },
    setRefreshCaseData: (params: boolean) => {
      dispatch({ type: REFRESH_CASE_DATA, params });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MTTDashboard) as any
);
