import SPINNER from '../assets/images/Spinner.gif';
import LOGO from '../assets/images/logo_urgently_verticle.svg';
import VERTICAL_LOGO from '../assets/images/logo_urgently.svg';
import LOGO_UPDATED from '../assets/images/logo_new_urgently.svg';
import CALIBER_LOGO from '../assets/images/logo_caliber.svg';
import ICON_ADD from '../assets/images/ic_add.svg';
import ICON_REPORTS from '../assets/images/ic_reports.svg';
import ICON_EDIT_WHITE from '../assets/images/ic_pencil_white.svg';
import ICON_INCOMING_TOWS from '../assets/images/services/Icon_Incoming_Tows_Gray.svg';
import ICON_MARKER_BATTERY from '../assets/images/services/markers/Pin_battery-icon.svg';
import ICON_MARKER_KEY_REPLACE from '../assets/images/services/markers/Pin_KeyReplace.svg';
import ICON_MARKER_FLAT_TIRE from '../assets/images/services/markers/Pin_flat-tire-icon.svg';
import ICON_MARKER_FUEL from '../assets/images/services/markers/Pin_fuel-icon.svg';
import ICON_MARKER_LOCKOUT from '../assets/images/services/markers/Pin_Lockout.svg';
import ICON_MARKER_TOW_TRUCK from '../assets/images/services/markers/Pin_Tow-Truck-icon.svg';
import ICON_MARKER_TIRE_INSTALL from '../assets/images/services/markers/Pin_TireInstall.svg';
import ICON_MARKER_MOTORCYCLE from '../assets/images/services/markers/Pin_Job_Motorcycle.svg';
import ICON_TOW_TRUCK from '../assets/images/services/Icon_Tow_Truck.svg';
import ICON_MAP from '../assets/images/ic_map.svg';
import ICON_EDIT from '../assets/images/ic_edit.svg';

// export Images
export const SPINNER_IMG = SPINNER;
export const URGENTLY_LOGO_IMG = LOGO;
export const URGENTLY_VERTICAL_LOGO_IMG = VERTICAL_LOGO;
export const URGENTLY_LOGO_UPDATED_IMG = LOGO_UPDATED;
export const CALIBER_LOGO_IMG = CALIBER_LOGO;
export const ICON_ADD_IMG = ICON_ADD;
export const ICON_REPORTS_IMG = ICON_REPORTS;
export const ICON_EDIT_WHITE_IMG = ICON_EDIT_WHITE;
export const ICON_INCOMING_TOWS_IMG = ICON_INCOMING_TOWS;
export const ICON_MARKER_BATTERY_IMG = ICON_MARKER_BATTERY;
export const ICON_MARKER_KEY_REPLACE_IMG = ICON_MARKER_KEY_REPLACE;
export const ICON_MARKER_FLAT_TIRE_IMG = ICON_MARKER_FLAT_TIRE;
export const ICON_MARKER_FUEL_IMG = ICON_MARKER_FUEL;
export const ICON_MARKER_LOCKOUT_IMG = ICON_MARKER_LOCKOUT;
export const ICON_MARKER_TOW_TRUCK_IMG = ICON_MARKER_TOW_TRUCK;
export const ICON_MARKER_TIRE_INSTALL_IMG = ICON_MARKER_TIRE_INSTALL;
export const ICON_MARKER_MOTORCYCLE_IMG = ICON_MARKER_MOTORCYCLE;
export const ICON_TOW_TRUCK_IMG = ICON_TOW_TRUCK;
export const ICON_MAP_IMG = ICON_MAP;
export const ICON_EDIT_IMG = ICON_EDIT;
