//import { printLog } from './logging';
import { object, string, is } from 'superstruct';

const MessagePayloadSuperStruct = object({
  type: string(),
  screen: string(),
  data: object(),
});

interface MessagePayload {
  type: string;
  screen: string;
  data: any;
}
interface CallbackType {
  (params: any): void;
}

export const EventName = {
  ANGULAR_INIT_DATA: 'ANGULAR_INIT_DATA',
  REACT_INIT_MTT_DASHBOARD: 'REACT_INIT_MTT_DASHBOARD',
  ANGULAR_SHOW_ERROR: 'ANGULAR_SHOW_ERROR',
  ANGULAR_SHOW_CASE_DETAIL: 'ANGULAR_SHOW_CASE_DETAIL',
};

export const ScreenName = {
  REPORT_SCREEN: `Report Screen`,
  MTT_DASHBOARD_SCREEN: `MTT Dashboard Screen`,
};

export const postMessage: any = {
  type: EventName.REACT_INIT_MTT_DASHBOARD,
  screen: ScreenName.MTT_DASHBOARD_SCREEN,
  data: {},
};

const Callbacks = {} as any;
export const Events = {
  on: (at: string, callback: CallbackType) => {
    return Events.listen(at, callback);
  },
  listen: (at: string, callback: CallbackType) => {
    /*eslint-disable eqeqeq*/
    if (at == '') {
      return false;
    }
    if (at in Callbacks) {
      Callbacks[at] = callback;
    } else {
      Callbacks[at] = callback;
    }
    return;
    // return id;
  },
  t: (at: string, data: any) => {
    return Events.trigger(at, data);
  },
  trigger: (at: string, data: any) => {
    data = data || '';
    const method = Callbacks[at];
    //printLog('obj=====', method);
    method(data);
  },
  rm: (at: string) => {
    return Events.remove(at);
  },
  remove: (at: string) => {
    delete Callbacks[at];
  },
  removeAll: (at: string) => {
    delete Callbacks[at];
  },
};

const isSecureRequest = (message: MessagePayload): any => {
  return is(message, MessagePayloadSuperStruct);
};

export function sendMessage(message: MessagePayload): void {
  window.parent.postMessage(
    JSON.stringify(message),
    `${document.referrer.slice(0, -1)}`
  );
}
/*NOTE: Main post message listner. */
window.addEventListener(
  'message',
  function (message: any) {
    if (message.data) {
      const receivedMessage = JSON.parse(message.data);
      if (message.origin === document.referrer.slice(0, -1)) {
        switch (receivedMessage.type) {
          case EventName.ANGULAR_INIT_DATA:
            if (isSecureRequest(receivedMessage)) {
              Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            }
            break;
          case EventName.ANGULAR_SHOW_ERROR:
            Events.trigger(EventName.ANGULAR_INIT_DATA, receivedMessage.data);
            break;
          default:
            break;
        }
      }
    }
  },
  false
);
