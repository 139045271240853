export const GET_PENDING_QUEUE_DATA = 'GET_PENDING_QUEUE_DATA';
export const GET_EXPIRED_QUEUE_DATA = 'GET_EXPIRED_QUEUE_DATA';
export const GET_DISPATCH_QUEUE_DATA = 'GET_DISPATCH_QUEUE_DATA';
export const GET_RESOLVED_QUEUE_DATA = 'GET_RESOLVED_QUEUE_DATA';
export const GET_PENDING_QUEUE_SUCCESS = 'GET_PENDING_QUEUE_SUCCESS';
export const GET_EXPIRED_QUEUE_SUCCESS = 'GET_EXPIRED_QUEUE_SUCCESS';
export const GET_DISPATCH_QUEUE_SUCCESS = 'GET_DISPATCH_QUEUE_SUCCESS';
export const GET_RESOLVED_QUEUE_SUCCESS = 'GET_RESOLVED_QUEUE_SUCCESS';
export const CLEAR_PENDING_QUEUE_DATA = 'CLEAR_PENDING_QUEUE_DATA';
export const CLEAR_EXPIRED_QUEUE_DATA = 'CLEAR_EXPIRED_QUEUE_DATA';
export const CLEAR_DISPATCH_QUEUE_DATA = 'CLEAR_DISPATCH_QUEUE_DATA';
export const CLEAR_RESOLVED_QUEUE_DATA = 'CLEAR_RESOLVED_QUEUE_DATA';
export const PENDING_QUEUE_UPDATE_DATA = 'PENDING_QUEUE_UPDATE_DATA';
export const TAKE_PENDING_CASE = 'TAKE_PENDING_CASE';
