import config from '../utils/config';
export const HOSTURL = process.env.REACT_APP_API_HOST;
export const NEW_HOSTURL = process.env.REACT_APP_NEW_API_HOST;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const INDUSTRY = process.env.REACT_APP_INDUSTRY;
export const DEVICE = process.env.REACT_APP_DEVICE;
export const APP_TYPE = process.env.REACT_APP_APP_TYPE;
export const HTTP_POST = `POST`;
export const HTTP_PUT = `PUT`;
export const HTTP_GET = `GET`;
export const SPECILIAST_QUEUE_CASE_EXPIRED = `SPECILIAST_QUEUE_CASE_EXPIRED`;
export const SPECIALIST_RESLOVED = `SPECIALIST_RESLOVED`;
export const SPECIALIST_DISPATCH = `SPECIALIST_DISPATCH`;
export const LOGIN_API = `${HOSTURL}/ops/login`;
export const RESPONSE_TYPE_BLOB = 'blob';
export const RESET_PASSWORD_API = (emailId: string): string =>
  `${HOSTURL}/ops/users/${emailId}/reset_password?portal=partner`;
export const CASES_API = (caseData: any): string => {
  return `${HOSTURL}/ops/cases/case-list?case_status=${caseData.status}&page=${caseData.page}&size=${caseData.size}&user_id=${caseData.userId}`;
};
export const SEARCH_CASES_API = (searchData: any): string => {
  return `${HOSTURL}/ops/cases/searchPaged?details=${searchData.searchValue}&page=${searchData.page}&size=${searchData.size}`;
};
export const GET_KEYS_API = `${HOSTURL}/ops/configs/keys?v=${API_VERSION}&env=${ENVIRONMENT}&industry=${INDUSTRY}&device=${DEVICE}&os=&app=${APP_TYPE}`;
export const LOGOUT_API = (uid: number): string =>
  `${HOSTURL}/ops/users/${uid}/logout`;
export const CASE_DETAIL_API = (caseId: number): string =>
  `${HOSTURL}/ops/cases/${caseId}`;
export const GET_COLORS_API = `${HOSTURL}/ops/vehicles/colors`;
export const SEND_EVENT_API = (eventData: any): string => {
  const time = new Date().getTime();
  return `${HOSTURL}/ops/events/cases?caseId=${eventData.data}&eventId=${eventData.event.EVENT_ID}&eventTimestamp=${time}&eventValue=${eventData.event.NAME}&source=${APP_TYPE}&version=${config.BUILD_VERSION}`;
};
export const GET_CASE_NOTES_API = (caseId: number): string =>
  `${HOSTURL}/ops/cases/${caseId}/notes`;
export const GET_DISABLEMENT_REASONS_API = (partnerId: number): string =>
  `${HOSTURL}/ops/configs/disablement-reasons?partnerId=${partnerId}`;
export const GET_CASE_MESSAGES_API = (caseId: number): string =>
  `${HOSTURL}/ops/messages/cases/${caseId}`;
export const GET_CASE_TAGS_API = (caseId: number): string =>
  `${HOSTURL}/ops/cases/case-tags?caseId=${caseId}`;
export const GET_JOB_DETAIL_API = (jobId: number): string =>
  `${HOSTURL}/ops/jobs/${jobId}`;
export const GET_DAMAGE_DETAIL_API = (params: any): string =>
  `${HOSTURL}/ops/cases/${params.caseId}/job/${params.jobId}/notes/event/${params.eventId}`;
export const REVISE_VEHICLE_INFO_API = (params: any): string =>
  `${HOSTURL}/ops/customers/${params.customerId}/vehicles/${params.vehicleId}`;
export const GET_HRS_OF_OPS_API = (facilityId: number): string =>
  `${HOSTURL}/ops/facilities/hours-of-operation?facilityId=${facilityId}`;
export const GET_FACILITY_NOTES_API = (param: any): string =>
  `${HOSTURL}/ops/facilities/tags/find-by-facility-id-and-tag?facilityId=${param.facilityId}&tag=${param.tag}`;
export const POST_HRS_OF_OPS_API = (facilityId: number): string =>
  `${HOSTURL}/ops/facilities/hours-of-operation?facilityId=${facilityId}`;
export const POST_FACILITY_NOTES_API = (): string =>
  `${HOSTURL}/ops/facilities/tags`;

export const GET_RECEIPT = (
  partnerCode: string,
  fromDate: string,
  toDate: string,
  page: number
): string =>
  `${NEW_HOSTURL}/invoicing-v1/invoicing/receipts/${partnerCode}?from=${fromDate}&to=${toDate}&size=10&page=${page}`;

export const GET_RECEIPT_DOWNLOAD = (
  partnerCode: string,
  fromDate: string,
  toDate: string
): string =>
  `${NEW_HOSTURL}/invoicing-v1/invoicing/receipts/${partnerCode}/download?from=${fromDate}&to=${toDate}`;

export const GET_PENDING_QUEUE = (
  queueId: number,
  page: number,
  size: number
): string =>
  `${HOSTURL}/ops/cases/find-by-queue-id?queue_id=${queueId}&page=${page}&size=${size}&is_disablement_reason_needed=true`;

export const GET_EXPIRED_QUEUE = (
  from: any,
  to: any,
  page: number,
  size: number
): string =>
  `${HOSTURL}/ops/cases/find-case-by-event?from=${from}&to=${to}&event=${SPECILIAST_QUEUE_CASE_EXPIRED}&page=${page}&size=${size}`;

export const GET_RESOLVED_QUEUE = (
  from: any,
  to: any,
  page: number,
  size: number
): string =>
  `${HOSTURL}/ops/cases/find-case-by-event?from=${from}&to=${to}&event=${SPECIALIST_RESLOVED}&page=${page}&size=${size}`;

export const GET_DISPATCH_QUEUE = (
  from: any,
  to: any,
  page: number,
  size: number
): string =>
  `${HOSTURL}/ops/cases/find-case-by-event?from=${from}&to=${to}&event=${SPECIALIST_DISPATCH}&page=${page}&size=${size}`;

export const TAKE_CASE = (caseId: number): string =>
  `${HOSTURL}/ops/cases/${caseId}/assign-to-me`;
