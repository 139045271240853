import { MTTDashboardData } from '../../types/reducerTypes';
import {
  GET_PENDING_QUEUE_SUCCESS,
  GET_EXPIRED_QUEUE_SUCCESS,
  GET_DISPATCH_QUEUE_SUCCESS,
  GET_RESOLVED_QUEUE_SUCCESS,
  CLEAR_PENDING_QUEUE_DATA,
  CLEAR_EXPIRED_QUEUE_DATA,
  CLEAR_DISPATCH_QUEUE_DATA,
  CLEAR_RESOLVED_QUEUE_DATA,
  PENDING_QUEUE_UPDATE_DATA,
} from './mttDashboardActionTypes';

const MTTDashboardInitial: MTTDashboardData = {
  pendingQueue: {
    content: [],
    meta: {
      totalElements: 0,
      totalPages: 0,
    },
  },
  expiredQueue: {
    content: [],
    meta: {
      totalElements: 0,
      totalPages: 0,
    },
  },
  dispatchQueue: {
    content: [],
    meta: {
      totalElements: 0,
      totalPages: 0,
    },
  },
  resolvedQueue: {
    content: [],
    meta: {
      totalElements: 0,
      totalPages: 0,
    },
  },
};

export const mttDashboardReducer = (
  state = MTTDashboardInitial,
  action: any
): object => {
  switch (action.type) {
    case GET_PENDING_QUEUE_SUCCESS:
      return {
        ...state,
        pendingQueue: {
          content: [...action.response.content],
          meta: action.response.meta,
        },
      };
    case GET_EXPIRED_QUEUE_SUCCESS:
      return {
        ...state,
        expiredQueue: {
          content: [...action.response.content],
          meta: action.response.meta,
        },
      };
    case GET_DISPATCH_QUEUE_SUCCESS:
      return {
        ...state,
        dispatchQueue: {
          content: [...action.response.content],
          meta: action.response.meta,
        },
      };
    case GET_RESOLVED_QUEUE_SUCCESS:
      return {
        ...state,
        resolvedQueue: {
          content: [...action.response.content],
          meta: action.response.meta,
        },
      };
    case CLEAR_PENDING_QUEUE_DATA:
      return {
        ...state,
        pendingQueue: {
          content: action.response,
        },
      };
    case CLEAR_EXPIRED_QUEUE_DATA:
      return {
        ...state,
        expiredQueue: {
          content: action.response,
        },
      };
    case CLEAR_DISPATCH_QUEUE_DATA:
      return {
        ...state,
        dispatchQueue: {
          content: action.response,
        },
      };
    case CLEAR_RESOLVED_QUEUE_DATA:
      return {
        ...state,
        resolvedQueue: {
          content: action.response,
        },
      };
    case PENDING_QUEUE_UPDATE_DATA:
      return {
        ...state,
        pendingQueue: {
          ...state.pendingQueue,
          content: Object.assign([...state.pendingQueue.content], {
            [action.params.indexRow]: action.params.response,
          }),
        },
      };
    default:
      return state;
  }
};
